@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply font-[Raleway];
        background: #000300;
    }
    li {
        @apply px-4;
        @apply cursor-pointer
    }
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #253341;
    -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0 1000px #001400 inset;
    box-shadow: 0 0 0 1000px #001400 inset;
    transition: background-color 5000s ease-in-out 0s;
    color: white;
}